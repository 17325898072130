//
// _galaxy.scss
//

.card {

    &::before,
    &::after {
        content: "";
        height: 13px;
        width: 13px;
        position: absolute;
        border: 3px solid;
    }

    &::before {
        border-color: var(--#{$prefix}border-color) var(--#{$prefix}border-color) transparent transparent;
        right: -3px;
        top: -3px;
        border-radius: 0 3px 0 0;
    }

    &::after {
        border-color: transparent transparent var(--#{$prefix}border-color) var(--#{$prefix}border-color);
        left: -3px;
        bottom: -3px;
        border-radius: 0 0 0 3px;
    }

    &.card-border-effect-none {

        &::before,
        &::after {
            display: none;
        }
    }
}



body {
    background-image: var(--#{$prefix}body-bg-image);
    background-attachment: fixed;

    &::before {
        content: "";
        position: absolute;
        inset: 0;
        z-index: -1;
        background-color: rgba($white, 0.30);
    }
}

[data-topbar="light"] {
    #page-topbar {
        &.topbar-shadow {
            background-color: var(--#{$prefix}secondary-bg);
        }
    }
}

[data-layout="vertical"][data-sidebar-size="sm"] .navbar-menu .navbar-nav .nav-item:hover>.menu-dropdown {
    background-color: $white;
}

.layout-rightside-col {
    @media (max-width: 1699.98px) {
        .card {
            background-color: var(--#{$prefix}secondary-bg);
        }
    }
}

@media (min-width: 992px) {
    .email-menu-sidebar {
        background-color: var(--#{$prefix}card-bg-fill);
    }
}

.apex-charts {
    .apexcharts-pie-series {
        path {
            stroke: var(--#{$prefix}secondary-bg);
        }
    }
}

.apexcharts-tooltip,
.swal2-popup {
    background-color: var(--#{$prefix}secondary-bg) !important;
}

.shepherd-content,
.card-bg-fill,
.navbar-landing.is-sticky,
#preloader,
.timeline-item .icon,
.department ul li a {
    background-color: var(--#{$prefix}secondary-bg);
}

// profile Page
.profile-wid-bg::before {
    background: none;
}

@media (min-width: 1350px) {

    .file-manager-sidebar,
    .email-detail-content,
    .file-manager-detail-content {
        background-color: var(--#{$prefix}card-bg-fill);
    }
}

[data-sidebar="light"][data-layout="vertical"][data-sidebar-size="sm-hover"] {
    .navbar-menu:hover {
        background-color: var(--#{$prefix}white);
    }
}

[data-bs-theme="dark"] {
    body {
        background-size: cover;

        &::before {
            background-color: rgba(4, 19, 36, 0.95);
        }
    }

    &[data-layout="vertical"][data-sidebar="dark"][data-sidebar-size="sm-hover"] {
        .navbar-menu:hover {
            background-color: var(--#{$prefix}secondary-bg);
        }
    }

    &[data-layout="vertical"][data-sidebar="dark"][data-sidebar-size="sm"] {
        .navbar-menu .navbar-nav .nav-item:hover>.menu-dropdown {
            background-color: var(--#{$prefix}secondary-bg);
        }
    }

    &[data-layout="vertical"][data-sidebar-size="sm"] {
        .navbar-menu .navbar-nav .nav-item:hover>a.menu-link {
            background-color: var(--#{$prefix}secondary-bg);
        }
    }
}

[data-layout="horizontal"] .navbar-nav .nav-item .nav-link[data-bs-toggle=collapse]:after {
    color: currentColor;
}


@media (max-width: 767.98px) {
    [data-topbar="light"] {
        .app-menu {
            background-color: var(--#{$prefix}topnav-bg);
        }
    }

    [data-bs-theme="dark"] {
        .vertical-sidebar-enable .app-menu {
            background-color: var(--#{$prefix}secondary-bg);
        }
    }
}

:root {
    //galaxy demos
    --#{$prefix}card-bg-fill: #{$card-bg-fill};

    @if $galaxy-body-img {
        &[data-body-image="img-1"] {
            --#{$prefix}body-bg-image: #{$body-bg-image-1};
        }

        &[data-body-image="img-2"] {
            --#{$prefix}body-bg-image: #{$body-bg-image-2};
        }

        &[data-body-image="img-3"] {
            --#{$prefix}body-bg-image: #{$body-bg-image-3};
        }
    }
}


//theme dark
@if $enable-dark-mode {
    @include color-mode(dark, true) {
        //galaxy demos
        --#{$prefix}card-bg-fill: #{$card-bg-fill-dark};

        @if $galaxy-body-img-dark {
            &[data-body-image="img-1"] {
                --#{$prefix}body-bg-image: #{$body-bg-image-1-dark};
            }

            &[data-body-image="img-2"] {
                --#{$prefix}body-bg-image: #{$body-bg-image-2-dark};
            }

            &[data-body-image="img-3"] {
                --#{$prefix}body-bg-image: #{$body-bg-image-3-dark};
            }

            [for="body-img-one"] {
                background-image: $body-bg-image-1-dark;
                background-size: cover;
            }

            [for="body-img-two"] {
                background-image: $body-bg-image-2-dark;
                background-size: cover;
            }

            [for="body-img-three"] {
                background-image: $body-bg-image-3-dark;
                background-size: cover;
            }
        }
    }
}